import React from 'react';
import { Link } from 'gatsby';
import { Formik, Form, Field, FieldProps } from 'formik';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Layout from '../components/Layout';
import Icon from '../components/Icon';
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../config';
import { CONTACT_US_ROUTE, CUSTOM_ORDER_REQUEST_ROUTE, EMAIL, HOME_ROUTE, WEDDING_ROUTE } from '../constants';
import useFirebase from '../useFirebase';

const SuccessDialog = ({ dialogOpen, setDialogOpen }) => {
    function handleClose() {
        setDialogOpen(false);
    }
    return (
        <Dialog
            open={dialogOpen}
            onClose={handleClose}
            maxWidth='sm'
        >
            <div className='text-center padding-2 position-relative'>
                <button className='btn close-dialog-btn flex-justify-center' onClick={handleClose}>
                    <Icon iconName='close' width={20} />
                </button>
                <div className="padding-2">
                    <div className="flex-justify-center success-icon">
                        <Icon width={100} iconName="commentCheck" />
                    </div>
                    <h4 className="margin-top-2">Success!</h4>
                    <p>We have received your message and will get back to you as soon as we can. In the meantime, feel free to explore our custom creations!</p>
                    <div className='grid-container add-width-1'>
                        <div className='grid-item-2 padding-1'>
                            <Link to={WEDDING_ROUTE} className='btn rounded-btn pink-btn full-width'>SHOP WEDDINGS</Link>
                        </div>
                        <div className='grid-item-2 padding-1'>
                            <Link to={HOME_ROUTE} className='btn rounded-btn white-btn full-width'>SHOP HOME</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

const fields = [
    {
        type: 'text',
        name: 'name',
        multiline: false,
        placeholder: '',
        label: 'Name'
    },
    {
        type: 'email',
        name: 'email',
        multiline: false,
        placeholder: '',
        label: 'Email'
    },
    {
        type: 'text',
        name: 'message',
        multiline: true,
        placeholder: 'How can we help you?',
        label: 'Message'
    }
];
const ContactPage: React.FC = () => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const firebase = useFirebase();
    const initialValues = {
        name: '',
        email: '',
        message: ''
    };
    return (
        <Layout pageTitle='Contact Us' pathname={CONTACT_US_ROUTE}>
            <div className='layout-container text-center'>
                <div className='contact-paper padding-2'>
                    <h3>Contact Us</h3>
                    <span className="underline" />
                    <Formik
                        initialValues={initialValues}
                        validate={({ name, email, message }) => {
                            const errors: {[key: string]: string} = {};
                            if (!name) {
                                errors.name = "Please enter your name";
                            }
                            if (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email) === false) {
                                errors.email = "Please enter a valid email";
                            }
                            if (!message) {
                                errors.message =  "Please enter a message";
                            }
                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting, resetForm }) => {
                            setStatus(null);
                            const contactUs = firebase.functions().httpsCallable('contactUs');
                            executeRecaptcha("CONTACT_US")
                            .then((recaptchaToken) => {
                                return contactUs({ ...values, recaptchaToken });
                            })
                            .then(() => {
                                setDialogOpen(true);
                                resetForm();
                            })
                            .catch(() => {
                                setStatus(`An error occurred - please try again later.`);
                                setSubmitting(false);
                            })
                        }}
                    >
                        {({ isSubmitting, status }) => {
                            return (
                                <Form>
                                    <div>
                                        {fields.map(({ name, type, multiline, placeholder, label }) => {
                                            return (
                                                <Field key={name} name={name}>
                                                    {({ field, meta }: FieldProps) => {
                                                        const hasError = Boolean(meta.touched && meta.error)
                                                        return (
                                                            <div>
                                                                <TextField
                                                                    {...field}
                                                                    type={type}
                                                                    variant='outlined'
                                                                    margin='normal'
                                                                    label={label}
                                                                    fullWidth
                                                                    multiline={multiline}
                                                                    rows={multiline ? 4 : undefined}
                                                                    error={hasError}
                                                                    helperText={hasError ? meta.error : ''}
                                                                    placeholder={placeholder}
                                                                />
                                                            </div>
                                                        )
                                                    }}
                                                </Field>
                                            )
                                        })}
                                        <div className="margin-top-2">
                                            {status && <div className="margin-bottom-1"><span className="error-msg">{status}</span></div>}
                                            <button 
                                                className={`btn primary-btn rounded-btn full-width${isSubmitting ? ' disabled' : ''}`}
                                            >{isSubmitting ? 'SUBMITTING...' : 'SUBMIT'}</button>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
                <div className='padding-bottom-4'>
                    <p><small>Have a custom order request? Try our <Link to={CUSTOM_ORDER_REQUEST_ROUTE} className="default">custom order request form</Link>!</small></p>
                </div>
            </div>
            <SuccessDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
        </Layout>
    )
}

export default () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY}>
            <ContactPage />
        </GoogleReCaptchaProvider>
    )
}
